<template>
  <div class="search-filter">
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :label-col="{span: 5}"
      :wrapper-col="{span: 19}"
    >
      <a-row :gutter="16">
        <a-col :span="colSpan">
          <a-row :gutter="0">
            <a-col :span="8">
              <a-form-model-item prop="keyword_type" :wrapper-col="{span: 24}">
                <a-select
                  v-model="form.keyword_type"
                  style="width: 100%;"
                >
                  <a-select-option
                    v-for="item in keywordTypeList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="16">
              <a-form-model-item prop="keyword" :wrapper-col="{span: 24}">  
                <a-input
                  v-model="form.keyword"
                  allow-clear
                  placeholder="请输入"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="媒体昵称" prop="nickname">
            <a-input
              v-model="form.nickname"
              allow-clear
              placeholder="请输入"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="来源" prop="app_id">
            <a-select
              v-model="form.app_id"
              allow-clear
              :default-active-first-option="false"
              placeholder="请选择"
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in sourceList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="主体" prop="principal_id">
            <a-select
              v-model="form.principal_id"
              allow-clear
              :default-active-first-option="false"
              option-filter-prop="children"
              placeholder="请选择"
              show-search
            >
              <a-select-option
                v-for="item in principalList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.principal_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="审核人" prop="auditor">
            <a-input
              v-model="form.auditor"
              allow-clear
              placeholder="请输入"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="媒体平台" prop="platform">
            <a-select
              v-model="form.platform"
              allow-clear
              :default-active-first-option="false"
              placeholder="请选择"
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in platformList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="审核状态" prop="status">
            <a-select
              v-model="form.status"
              allow-clear
              :default-active-first-option="false"
              placeholder="请选择"
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in statusList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="提交时间" prop="commit_time">
            <a-range-picker
              v-model="form.commit_time"
              value-format="YYYY-MM-DD"
              style="width: 100%;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="审核时间" prop="status_time">
            <a-range-picker
              v-model="form.status_time"
              value-format="YYYY-MM-DD"
              style="width: 100%;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item :wrapper-col="{span: 24}">
            <div class="btns">
              <a-button class="mr-8" @click="handleReset">
                重置
              </a-button>
              <a-button type="primary" :loading="loading" @click="handleSearch">
                查询
              </a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import resizeMixin from "@/mixins/dataTableMixin";
import { sourceList, platformList, statusList } from '../../constant'

const keywordTypeList = [
  { label: "经销商名称", value: 1 },
  { label: "经销商编号", value: 2 },
  { label: "媒体号ID", value: 3 },
  { label: "风火轮帐号", value: 4 }
]

export default {
  name: 'SearchFilter',
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object
    },
    principalList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    this.$_resizeHandler = () => {
      this.setColSpan();
    };

    return {
      colSpan: 12,
      colSpan2: 12,
      keywordTypeList,
      sourceList,
      platformList,
      statusList
    };
  },
  created() {
    this.setColSpan();
  },
  methods: {
    setColSpan() {
      const width = document.documentElement.offsetWidth;

      if (width < 1200) {
        this.colSpan = 12;
        this.colSpan2 = 12;
      } else if (width >= 1200 && width < 1600) {
        this.colSpan = 8;
        this.colSpan2 = 24;
      } else if (width >= 1600) {
        this.colSpan = 6;
        this.colSpan2 = 18;
      }
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.$emit('search', {...this.form});
    },
    handleSearch() {
      this.$emit('search', {...this.form});
    },
  }
}
</script>

<style lang="scss" scoped>
.mr-8 {
  margin-right: 8px;
}

.search-filter {
  padding: 24px 24px 16px;
  background: #fff;
}

.ant-form-item {
  margin-bottom: 8px;
}

.btns {
  text-align: right;
}
</style>
