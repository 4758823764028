<template>
  <section class="page">
    <div class="page-content">
      <search-filter
        class="mb-16"
        :loading="loading"
        :form="searchForm"
        :principal-list="principalList"
        @search="handleSearch"
      />

      <data-table
        ref="dataTable"
        :loading="loading"
        :data="data"
        :pagination="pagination"
        @pagination-change="handlePaginationChange"
        :is-can-select="isCanSelect"
        @batch-pass="handleBatchPass"
        :export-loading="exportLoading"
        @export="handleExport"
        @verify="handleVerify"
        @audit="handleAudit"
        @subscribe="handleSubscribe"
      />
    </div>

    <reason-modal
      :visible="reasonVisible"
      :form="reasonForm"
      :reason-type-list="reasonTypeList"
      :confirm-loading="reasonConfirmLoading"
      @cancel="handleReasonCancel"
      @confirm="handleReasonConfirm"
    />

    <a-modal v-model="modalVisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <template slot="title">
        <a-space>
          <span>订阅待审消息</span>
          <span style="color: #b3b3b3">品牌需要审核时，企微通知到自己</span>
        </a-space>
      </template>
      <a-form-model ref="formRef" :model="brandForm" v-bind="formItemLayout">
        <a-form-model-item label="品牌" prop="selectBrands">
          <a-select
            mode="multiple"
            v-model="brandForm.selectBrands"
            placeholder="请选择品牌, 置空为取消订阅"
            :filter-option="filterOption"
            show-search
            allow-clear
          >
            <a-select-option
              :disabled="item.status == 2 ? true : false"
              v-for="item in brandList"
              :key="item.principal_id"
            >
              {{ item.principal_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </section>
</template>

<script>
import SearchFilter from './components/SearchFilter';
import DataTable from './components/DataTable';
import ReasonModal from './components/ReasonModal';
import { downloadExcel } from '@/utils';
import auditApi from '@/api/auditManage';
import trainingApi from '@/api/training_list';

export default {
  name: 'auditList',
  components: {
    SearchFilter,
    DataTable,
    ReasonModal,
  },
  data() {
    return {
      brandList: [],
      formItemLayout: {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 18,
          },
        },
      },
      brandForm: {
        selectBrands: [],
      },
      modalVisible: false,
      loading: false,
      searchForm: {
        keyword_type: 1,
        keyword: '',
        nickname: '',
        app_id: undefined,
        principal_id: undefined,
        auditor: '',
        platform: undefined,
        status: undefined,
        commit_time: [],
        status_time: [],
      },
      principalList: [],
      data: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      isCanSelect: false,
      exportLoading: false,
      reasonVisible: false,
      reasonForm: {
        reasonType: undefined,
        reason: '',
      },
      reasonTypeList: [],
      reasonConfirmLoading: false,
      confirmLoading: false,
    };
  },

  mounted() {
    this.searchForm.status = 3;
    this.fetchPrincipalList();
    this.fetchAuditList();
    this.fetchAuditPrincipal();
  },
  watch: {
    'searchForm.status'(newVal, oldVal) {
      if (newVal !== 3) {
        this.isCanSelect = false;
      }
    },
  },
  methods: {
    filterOption(inputValue, option) {
      return option.componentOptions.children[0].text.indexOf(inputValue) > -1;
    },
    // 品牌改变
    // handleBrandChange(val){
    //   // const index = this.brandList.findIndex(item => item.id === val);
    //   // this.brandForm.selectBrands.push(this.brandList[index]);
    // },
    // 获取订阅消息品牌列表
    async fetchAuditPrincipal() {
      try {
        const { data } = await auditApi.fetchAuditPrincipalApi();
        data.forEach((item) => {
          if (item.status == 2) {
            item.principal_name += '（免审）';
          }
        });
        this.brandList = data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取主体列表
    async fetchPrincipalList() {
      try {
        const { code, data, message } = await trainingApi.getPrincipals();

        if (code === 200) {
          this.principalList = data;
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 获取审核列表
    async fetchAuditList() {
      this.loading = true;
      try {
        const { keyword_type, keyword, commit_time, status_time, ...restSearchForm } = this.searchForm;
        const { page, size } = this.pagination;
        const commit_time_from = commit_time.length > 0 ? `${commit_time[0]} 00:00:00` : '';
        const commit_time_to = commit_time.length > 0 ? `${commit_time[1]} 23:59:59` : '';
        const status_time_from = status_time.length > 0 ? `${status_time[0]} 00:00:00` : '';
        const status_time_to = status_time.length > 0 ? `${status_time[1]} 23:59:59` : '';
        let params = {
          ...restSearchForm,
          commit_time_from,
          commit_time_to,
          status_time_from,
          status_time_to,
          page_num: page,
          page_size: size,
        };
        keyword_type === 1 && (params.dealer_name = keyword);
        keyword_type === 2 && (params.store_code = keyword);
        keyword_type === 3 && (params.author_id = keyword);
        keyword_type === 4 && (params.creator = keyword);

        const { code, data, message } = await auditApi.fetchAuditList(params);

        if (code === 200) {
          this.data = data.list;
          this.pagination.total = data.total;
          this.isCanSelect = this.searchForm.status === 3;
          this.loading = false;
        } else {
          this.$message.error(message);
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    // 处理查询
    handleSearch() {
      this.pagination.page = 1;
      this.fetchAuditList();
    },
    // 处理分页改变
    handlePaginationChange(page, size) {
      this.pagination.page = page;
      this.pagination.size = size;
      this.fetchAuditList();
    },
    // 处理批量审核通过
    handleBatchPass(selectedRowKeys, selectedRows) {
      this.batchAudit(selectedRowKeys);
    },
    // 批量审核通过媒体号
    async batchAudit(selectedRowKeys) {
      try {
        const params = selectedRowKeys;
        const { code, message } = await auditApi.batchAudit(params);

        if (code === 200) {
          this.$message.success('批量通过操作成功');
          this.fetchAuditList();
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 处理导出
    handleExport() {
      this.exportAuditList();
    },
    // 导出审核列表
    async exportAuditList() {
      this.exportLoading = true;
      try {
        const { keyword_type, keyword, commit_time, status_time, ...restSearchForm } = this.searchForm;
        const commit_time_from = commit_time.length > 0 ? `${commit_time[0]} 00:00:00` : '';
        const commit_time_to = commit_time.length > 0 ? `${commit_time[1]} 23:59:59` : '';
        const status_time_from = status_time.length > 0 ? `${status_time[0]} 00:00:00` : '';
        const status_time_to = status_time.length > 0 ? `${status_time[1]} 23:59:59` : '';
        let params = {
          ...restSearchForm,
          commit_time_from,
          commit_time_to,
          status_time_from,
          status_time_to,
          page_num: -1,
          page_size: -1,
        };
        keyword_type === 1 && (params.dealer_name = keyword);
        keyword_type === 2 && (params.store_code = keyword);
        keyword_type === 3 && (params.author_id = keyword);
        keyword_type === 4 && (params.creator = keyword);

        const res = await auditApi.exportAuditList(params);

        downloadExcel(res, '审核列表数据');
        this.exportLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async handleVerify(record) {
      try {
        const { id, platform } = record;
        const params = {
          authUserInfoId: id,
          platform,
        };
        const { code } = await auditApi.fetchAuditAuthorInfo(params);

        if (code === 200) {
          this.$message.success('校验成功，可进行审核。');
          this.fetchAuditList();
        } else if (code === 1241001308) {
          this.$confirm({
            title: '媒体号信息不完善',
            content: (h) => <div>该媒体号需解除私密帐号，且发布至少一个公开视频。</div>,
            icon: 'info-circle',
            okText: '审核不通过',
            onOk: () => {
              this.auditType = 1;
              this.selectedRecord = record;
              this.reasonVisible = true;
              this.fetchAuditReason();
            },
            cancelText: '稍后处理',
          });
        } else if (code === 1902001026) {
          this.$confirm({
            title: '媒体号校验超时',
            content: (h) => (
              <div>
                校验超时需用户重新授权，该条记录自动“不通过”。因无法确认媒体号信息，该条记录不会在风火轮App内展示。
              </div>
            ),
            okText: '确认',
            onOk: () => {
              this.fetchAuditList();
            },
            cancelText: '',
          });
        } else {
          this.$message.error('系统繁忙，请间隔1小时后重试。');
        }
        this.$refs.dataTable.restLoading(record);
      } catch (e) {
        console.error(e);
        this.$refs.dataTable.restLoading(record);
      }
    },
    // 处理审核通过/不通过
    async handleAudit(type, record, loading) {
      if (this.selectedRecord) {
        this.$message.info('有记录正在操作中，请稍后');
        return false;
      }

      this.auditType = type;
      this.selectedRecord = record;

      if (this.auditType === 1) {
        // 不通过
        this.reasonVisible = true;
        this.fetchAuditReason(loading);
      }

      if (this.auditType === 2) {
        // 通过
        this.fetchAuditDealer(loading);
      }
    },
    // 获取审核不通过原因列表
    async fetchAuditReason(loading) {
      try {
        const { code, data, message } = await auditApi.fetchAuditReason();

        if (code === 200) {
          this.reasonTypeList = data || [];
        } else {
          this.$message.error(message);
        }
        this.$refs.dataTable.resetRecordLoading(this.selectedRecord, loading);
      } catch (e) {
        console.log(e);
        this.$refs.dataTable.resetRecordLoading(this.selectedRecord, loading);
      }
    },
    handleReasonConfirm() {
      this.reasonConfirmLoading = true;
      this.audit();
    },
    handleReasonCancel() {
      this.reasonVisible = false;
      this.auditType = undefined;
      this.selectedRecord = undefined;
    },
    // 获取媒体号绑定的经销商信息
    async fetchAuditDealer(loading) {
      try {
        const params = {
          author_id: this.selectedRecord.author_id,
          platform: this.selectedRecord.platform,
        };
        const { code, data, message } = await auditApi.fetchAuditDealer(params);

        if (code === 200) {
          if (data) {
            this.$confirm({
              title: '此媒体帐号已绑定经销商',
              content: (
                <div>
                  <div style="margin: 10px 0 0;">
                    <div>{data.dealer_name}</div>
                    <div>ID：{data.dealer_id}</div>
                    <div>编号：{data.store_code}</div>
                  </div>
                  <div style="margin: 20px 0 0;color: #FF4D4F;">媒体号只能绑定一个经销商，继续则会解除原绑定关系</div>
                </div>
              ),
              width: 450,
              icon: 'info-circle',
              okText: '继续',
              onOk: () => {
                this.audit();
              },
              onCancel: () => {
                this.auditType = undefined;
                this.selectedRecord = undefined;
              },
            });
          } else {
            this.audit();
          }
        } else {
          this.$message.error(message);
          this.auditType = undefined;
          this.selectedRecord = undefined;
        }
        this.$refs.dataTable.resetRecordLoading(this.selectedRecord, loading);
      } catch (e) {
        this.auditType = undefined;
        this.selectedRecord = undefined;
        this.$refs.dataTable.resetRecordLoading(this.selectedRecord, loading);
      }
    },
    // 审核媒体号
    async audit() {
      try {
        let params = {
          auth_user_id: this.selectedRecord.id,
          status: this.auditType,
        };

        if (this.auditType === 1) {
          // 不通过
          const { reasonType, reason } = this.reasonForm;

          if (reasonType === 'self_made_reasons') {
            // 自拟原因
            params.reason = reason;
          } else {
            const index = this.reasonTypeList.findIndex((item) => item.key === reasonType);

            params.reason = index > -1 ? this.reasonTypeList[index].value : '';
          }
        }

        const { code, message } = await auditApi.audit(params);

        if (code === 200) {
          if (this.auditType === 1) {
            this.$message.success('不通过操作成功');
            this.reasonConfirmLoading = false;
            this.reasonVisible = false;
          }

          if (this.auditType === 2) {
            this.$message.success('通过操作成功');
          }

          this.auditType = undefined;
          this.selectedRecord = undefined;
          this.fetchAuditList();
        } else {
          this.$message.error(message);

          if (this.auditType === 1) {
            this.reasonConfirmLoading = false;
          }

          if (this.auditType === 2) {
            this.auditType = undefined;
            this.selectedRecord = undefined;
          }
        }
      } catch (e) {
        console.log(e);

        if (this.auditType === 1) {
          this.reasonConfirmLoading = false;
        }

        if (this.auditType === 2) {
          this.auditType = undefined;
          this.selectedRecord = undefined;
        }
      }
    },
    // 处理订阅消息
    handleSubscribe() {
      this.modalVisible = true;
      this.fetchAuditNoticeDetail();
    },
    // 获取订阅消息详情
    async fetchAuditNoticeDetail() {
      const res = await auditApi.fetchAuditNoticeDetailApi();
      if (res.code == 200) {
        res.data.forEach((item) => {
          if (item.status == 1) {
            this.brandForm.selectBrands.push(item.principal_id);
          }
        });
      } else {
        this.$message.error(res.message);
      }
    },
    // 处理对话框确认--订阅消息添加
    handleOk() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.confirmLoading = true;
          let params = [];
          this.brandList.forEach((item) => {
            if (this.brandForm.selectBrands.indexOf(item.principal_id) != -1) {
              params.push({ principal_id: item.principal_id, principal_name: item.principal_name });
            }
          });
          const res = await auditApi.fetchAuditNoticeApi(params);
          if (res.code == 200) {
            this.$message.success(res.message);
            this.confirmLoading = false;
            this.modalVisible = false;
            this.brandForm.selectBrands = [];
          } else {
            this.confirmLoading = false;
            this.$message.error(res.message);
          }
        }
      });
    },
    // // 处理对话框取消
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.brandForm.selectBrands = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}
</style>
