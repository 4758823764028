<template>
  <div class="data-table" ref="dataTable">
    <div class="action-bar mb-16">
      <div class="action-bar__left">
        <a-button
            class="mr-8"
            v-if="selectedRowKeys.length > 0"
            type="primary"
            @click="handleBatchPass"
        >
          批量通过
        </a-button>
        <a-button
            type="primary"
            @click="handleSubscribe"
        >
          订阅待审消息
        </a-button>
      </div>
      <div class="action-bar__right">
        <a-button
            type="primary"
            :loading="exportLoading"
            @click="handleExport"
        >
          导出
        </a-button>
      </div>
    </div>

    <a-alert
        class="mb-16"
        v-if="selectedRowKeys.length > 0"
        type="info"
        :message="`已选择 ${selectedRowKeys.length} 项`"
        close-text="取消选择"
        @close="handleRowSelectCancel"
    />

    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-key="(record, index) => record.id"
        :row-selection="rowSelection"
        :pagination="false"
        :scroll="scroll"
    >
      <div slot="statusTitle">
        审核状态
        <a-tooltip>
          <template slot="title">
            <p> 校验中：</p>
            爬虫挂了，未获取到媒体号ID，需手动校验，校验期间风火轮App内不会展示该条记录。
            校验有时限，超时后需用户重新授权，抖音7天超时，快手2天，懂车帝（头条号）不定时。
          </template>
          <a-icon type="exclamation-circle"/>
        </a-tooltip>
      </div>
      <div class="dealer" slot="dealer" slot-scope="text, record">
        <div class="detail-item">
          <span class="detail-item__label">名称：</span>
          <a class="detail-item__value"
             :href="`${locationOrigin}/dealerManage/dealerDetail?dealer_id=${record.dealer_id}`"
             target="_blank"
          >{{ record.dealer_name || "-" }}</a>
          <!--<span class="detail-item__value" style="color: #40a9ff;cursor: pointer"-->
          <!--      @click="goNewPage(record)">{{ record.dealer_name || "-" }}</span>-->
        </div>
        <div class="detail-item">
          <span class="detail-item__label">编号：</span>
          <span class="detail-item__value">{{ record.store_code || "-" }}</span>
        </div>
      </div>
      <div class="source" slot="source" slot-scope="text, record">
        <span v-if="record.app_id === 'data-auth'">
          {{ record.app_id | sourceFilter }}
        </span>
        <span v-if="record.app_id === 'fhl-auth'">
          {{ record.app_id | sourceFilter }}：
          <a :href="`${locationOrigin}/fhlUser/detail?userId=${record.custom_id}`" target="_blank">
            {{ record.creator }}
          </a>
        </span>
      </div>
      <div class="account" slot="account" slot-scope="text, record">
        <div class="account_avatar">
          <a-avatar
              icon="user"
              :src="record.avatar"
              :size="60"
          />
          <i
              :class="['icon', {
              'icon-douyin': record.platform === 'douyin',
              'icon-kuaishou': record.platform === 'kuaishou',
              'icon-dongchedi': (
                record.platform === 'dongchedi' ||
                record.platform === 'toutiao' ||
                record.platform === 'maichetong'
              )
            }]"
          >
          </i>
        </div>
        <div class="account__info">
          <!-- 帐号昵称 -->
          <template>
            <div v-if="record.id && record.platform === 'douyin'">
              <a :href="`https://www.douyin.com/search/${record.nickname}?source=switch_tab&type=user`" target="_blank">
                {{ record.nickname }}
              </a>
            </div>
            <div v-else-if="record.id && record.platform === 'kuaishou'">
              <a :href="`https://www.kuaishou.com/search/author?searchKey=${record.nickname}`" target="_blank">
                {{ record.nickname }}
              </a>
            </div>
            <div
                v-else-if="record.id && (record.platform === 'dongchedi' || record.platform === 'toutiao' || record.platform === 'maichetong')">
              <a :href="`https://www.dongchedi.com/user/${record.author_id}`" target="_blank">
                {{ record.nickname }}
              </a>
            </div>
            <div v-else>
              {{ record.nickname || "-" }}
            </div>
          </template>
          <!-- 平台号/ID -->
          <template>
            <div class="detail-item" v-if="record.code && record.platform === 'douyin'">
              <span class="detail-item__label">抖音号：</span>
              <span class="detail-item__value">{{ record.code || "-" }}</span>
            </div>
            <div class="detail-item" v-else-if="record.code && record.platform === 'kuaishou'">
              <span class="detail-item__label">快手号：</span>
              <span class="detail-item__value">{{ record.code || "-" }}</span>
            </div>
            <div class="detail-item" v-else>
              <span class="detail-item__label">ID：</span>
              <span class="detail-item__value">{{ record.author_id || "-" }}</span>
            </div>
          </template>
          <template>
            <div class="detail-item" v-if="record.verify_name">
              <span class="b_v"></span>
              <span class="detail-item__value">{{ record.verify_name || "-" }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="status" slot="status" slot-scope="text, record">
        <template v-if="record.status === 3 && !record.author_id">
          校验中
        </template>
        <template v-else-if="record.status === 4 && !record.author_id">
          校验超时
        </template>
        <template v-else>
          {{ record.status | statusFilter }}
        </template>
      </div>
      <div class="action" slot="action" slot-scope="text, record">
        <template v-if="record.status === 1">
          不通过原因：{{ record.reason || "-" }}
        </template>
        <template v-else-if="record.status === 3 && !record.author_id">
          <div>等待校验完后再审核</div>
          <a-button type="link" :loading="record.verify_loading" @click="handleVerify(record)">
            手动校验
          </a-button>
        </template>
        <template v-else-if="record.status === 3 && record.author_id">
          <a-button type="link" :loading="record.status_loading" @click="handleAudit(2, record,'status_loading')">
            通过
          </a-button>
          <a-divider type="vertical"/>
          <a-button type="link" style="color: red" :loading="record.record_loading"
                    @click="handleAudit(1, record,'record_loading')">
            不通过
          </a-button>
        </template>
        <template v-else-if="record.status === 4 && !record.author_id">
          媒体号校验超时，需用户重新授权
        </template>
      </div>
    </a-table>

    <base-pagination
        :currentPage="pagination.page"
        :pageSize="pagination.size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import resizeMixin from "@/mixins/dataTableMixin";
import { sourceFilter, statusFilter } from "../../constant";

const columns = [
  {
    title: "主体",
    dataIndex: "principal_name",
    width: 150,
    customRender: (text, record, index) => {
      return record.principal_name || "-";
    }
  },
  {
    title: "经销商",
    key: "dealer",
    width: 200,
    scopedSlots: {customRender: "dealer"}
  },
  {
    title: "来源",
    key: "source",
    width: 150,
    scopedSlots: {customRender: "source"}
  },
  {
    title: "媒体号",
    key: "account",
    width: 400,
    scopedSlots: {customRender: "account"}
  },
  {
    title: "提交时间",
    dataIndex: "ctime",
    width: 170
  },
  {
    title: "审核时间",
    dataIndex: "status_time",
    width: 170,
    customRender: (text, record, index) => {
      return record.status_time || "-";
    }
  },
  {
    // title: "审核状态",
    key: "status",
    width: 160,
    scopedSlots: {customRender: "status"},
    slots: {title: "statusTitle"},
  },
  {
    title: "审核人",
    dataIndex: "auditor",
    width: 100,
    customRender: (text, record, index) => {
      return record.auditor || "-";
    }
  },
  {
    title: "操作",
    fixed: "right",
    width: 200,
    scopedSlots: {customRender: "action"}
  }
];

export default {
  name: "DataTable",
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        };
      }
    },
    isCanSelect: {
      type: Boolean,
      default: false
    },
    exportLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.$_resizeHandler = () => {
      this.setTableScroll();
    };

    return {
      columns,
      scroll: {},
      selectedRowKeys: [],
      selectedRows: [],
      locationOrigin: location.origin
    };
  },
  computed: {
    rowSelection() {
      if(this.isCanSelect) {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows;
          }
        };
      } else {
        return null;
      }
    }
  },
  mounted() {
    this.setTableScroll();
  },
  methods: {
    setTableScroll() {
      const width = this.$refs.dataTable.offsetWidth;
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0);

      if(width < columnsWidth) {
        this.scroll = {x: columnsWidth};
      } else {
        this.scroll = {};
      }
    },
    handlePaginationChange(page, size) {
      this.$emit("pagination-change", page, size);
    },
    handleBatchPass() {
      this.$emit(
          "batch-pass",
          [...this.selectedRows.filter(item => item.author_id).map(item => item.id)],
          [...this.selectedRows.filter(item => item.author_id)]
      );
    },
    handleRowSelectCancel() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    handleExport() {
      this.$emit("export");
    },
    handleVerify(record) {
      this.$set(record, "verify_loading", true);
      this.$emit("verify", record);
    },
    handleAudit(type, record, loading) {
      this.$set(record, loading, true);
      this.$emit("audit", type, record, loading);
    },
    restLoading(record) {
      this.$set(record, "verify_loading", false);
    },
    resetRecordLoading(record, lodaing) {
      this.$set(record, lodaing, false);
    },
    // 订阅待审
  handleSubscribe(){
    this.$emit("subscribe")
  }
  },
  filters: {
    sourceFilter,
    statusFilter
  },
};
</script>

<style lang="scss" scoped>
.mr-8 {
  margin-right: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.data-table {
  padding: 24px;
  background: #fff;
}

.action-bar {
  display: flex;
  align-items: center;

  &__left {
    flex: 1;
  }
}

.detail-item {
  display: flex;

  &__value {
    flex: 1;
  }

  .b_v {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url("../../../../../../assets/b_v.webp");
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 4px;
  }
}

.account {
  display: flex;
  align-items: center;

  .account_avatar {
    position: relative;

    .icon {
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 18px;
      height: 18px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;

      &-douyin {
        background-image: url("../../../../../../assets/icon/icon_douyin.svg");
      }

      &-kuaishou {
        background-image: url("../../../../../../assets/icon/icon_kuaishou.svg");
      }

      &-dongchedi {
        background-image: url("../../../../../../assets/icon/icon_dongchedi.svg");
      }
    }
  }

  .account__info {
    padding: 0 10px;
  }
}

.danger {
  color: #FF4D4F;
}
</style>
