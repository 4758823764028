export const sourceList = [
  { label: "Web", value: "data-auth" },
  { label: "风火轮", value: "fhl-auth" }
]

export const sourceFilter = (val) => {
  const index = sourceList.findIndex(item => item.value === val)

  return index > -1 ? sourceList[index].label : "-"
}

export const platformList = [
  { label: "抖音", value: "douyin" },
  { label: "快手", value: "kuaishou" },
  { label: "懂车帝（头条号）", value: "toutiao" }
]

export const platformFilter = (val) => {
  const index = platformList.findIndex(item => item.value === val)

  return index > -1 ? platformList[index].label : "-"
}

export const statusList = [
  { label: "不通过", value: 1 },
  { label: "通过 ", value: 2 },
  { label: "待审核", value: 3 },
  { label: "校验超时", value: 4 }
]

export const statusFilter = (val) => {
  const index = statusList.findIndex(item => item.value === val)

  return index > -1 ? statusList[index].label : "-"
}
