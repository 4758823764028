<template>
  <a-modal
    title="不通过原因"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item prop="reasonType">
        <a-select
          v-model="form.reasonType"
          :default-active-first-option="false"
          placeholder="请选择原因"
          style="width: 100%;"
        >
          <a-select-option
            v-for="item in reasonTypeList"
            :key="item.key"
            :value="item.key"
          >
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.reasonType === 'self_made_reasons'"  prop="reason">
        <a-textarea
          v-model.trim="form.reason"
          placeholder="不超过40个汉字"
          :rows="4"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'ReasonModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default () { return {} }
    },
    reasonTypeList: {
      type: Array,
      default () { return [] }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
        reasonType: [
          { required: true, message: '请选择原因', trigger: 'change' }
        ],
        reason: [
          { max: 40, message: '不超过40个汉字', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        }
      })
    },
    handleCancel () {
      this.$refs.form.resetFields()
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less" scoped>

</style>
